<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ isNewItemLocal ? "Nova" : "Alterar" }} notícia</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new p-5"
      :settings="settings"
    >
      <form>
        <p>Título da notícia</p>
        <vs-input
          v-validate="'required'"
          name="title"
          class="w-full mb-6"
          v-model="item.title"
          :color="item.title ? 'success' : 'danger'"
        />
        <p>Tipo:</p>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="item.typeSelected"
          :options="types"
        />
        <p>Descrição:</p>
        <vs-textarea
          v-validate="'required'"
          name="description"
          class="w-full mb-6"
          v-model="item.description"
          :color="item.description ? 'success' : 'danger'"
        />
        <p>Enviar notificação</p>
        <vs-switch v-model="item.push" />
        <p>Selecione o banner</p>
        <input type="file" ref="file" name="banner" @change="onFileUpload" />
      </form>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitAction">Salvar</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >Cancelar</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import moment from "moment";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import ggcsApi from "@/services/ggcs";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    isNewItem: {
      type: Boolean,
      required: true,
    },
    editItemData: {
      type: Object,
      required: false,
    },
    selectedDate: {
      type: Date,
      required: false,
    },
  },
  data() {
    return {
      news: [],
      item: {
        title: null,
        description: null,
        typeSelected: null,
        banner: null,
        push: false,
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      types: [],
      error: "",
    };
  },
  watch: {
    editItemData() {
      this.item = {
        news_id: this.editItemData.news_id,
        title: this.editItemData.title,
        description: this.editItemData.description,
      };
      this.item.typeSelected = this.types.find(
        (item) => item.id == this.editItemData.type
      );
    },
    isNewItem(newVal) {
      if (newVal) {
        this.clearData();
      }
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isNewItemLocal: {
      get() {
        return this.isNewItem;
      },
    },
    formatDate: {
      get() {
        return moment(this.selectedDate).format("DD/MM");
      },
    },
  },
  methods: {
    validateData() {
      return this.item.title && this.item.description && this.item.typeSelected;
    },
    validateFile() {
      if (this.item.banner) return this.item.banner.type == "image/png";
      else return -1;
    },

    submitAction() {
      if (this.validateData())
        if (this.isNewItemLocal) this.createSubmit();
        else this.updateSubmit();
      else {
        this.error = "Formulário incompleto. Preencha corretamente os dados.";
        this.notifyError();
      }
    },

    onFileUpload(event) {
      this.item.banner = event.target.files[0];
    },

    createSubmit() {
      const payload = `{"title": "${this.item.title}","description":"${this.item.description}","type":"${this.item.typeSelected.id}","push":"${this.item.push}"}`;
      const formData = new FormData();
      formData.append("payload", payload.toString());
      if (this.validateFile() == true) {
        formData.append("banner", this.item.banner, this.item.banner.name);
        this.renderLoading();
        ggcsApi
          .post("news", formData)
          .then((res) => {
            this.$vs.loading.close();
            const { data } = res;
            if (data.success) {
              this.notifySuccess();
              this.isSidebarActiveLocal = false;
              this.clearData();
              this.$emit("didAddItem");
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.error = "Erro ao tentar cadastrar notícia: " + error;
            this.notifyError();
          });
      } else {
        this.error =
          "Selecione uma imagem válida do tipo PNG para cadastrar uma notícia.";
        this.notifyError();
      }
    },
    updateSubmit() {
      const payload = `{"title": "${this.item.title}","description":"${this.item.description}","type":"${this.item.typeSelected.id}"}`;
      const formData = new FormData();
      formData.append("payload", payload.toString());
      const file = this.validateFile();

      if (file == false) {
        this.error =
          "Selecione uma imagem válida do tipo PNG para alterar a imagem de uma notícia.";
        this.notifyError();
      } else {
        if (file == true)
          formData.append("banner", this.item.banner, this.item.banner.name);

        const id = this.editItemData.news_id;
        const self = this;
        self.renderLoading();
        ggcsApi
          .put(`news/${id}`, formData)
          .then((res) => {
            self.$vs.loading.close();
            const { data } = res;
            if (data.success) {
              self.notifySuccess();
              self.$emit("didAddItem");
            }
            self.clearData();
            self.isSidebarActiveLocal = false;
          })
          .catch((error) => {
            self.$vs.loading.close();
            self.error = "Erro ao tentar editar: " + error;
            self.notifyError();
          });
      }
    },
    fetchTypes() {
      const self = this;
      ggcsApi
        .get(`news/types/`)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            self.types = data.result.map((type) => {
              return {
                id: type.id,
                label: type.name,
              };
            });
          }
        })
        .catch(() => {
          self.$vs.loading.close();
        });
    },
    clearData() {
      this.item = {
        title: null,
        description: null,
        type: null,
        banner: null,
        push: false,
      };
    },
    renderLoading() {
      this.$vs.loading({
        // container: "#items-list-view",
        scale: 0.45,
      });
    },
    notifySuccess() {
      const text = this.isNewItemLocal ? "adicionada" : "alterada";
      this.$vs.notify({
        title: "Sucesso",
        text: `Notícia ${text} com sucesso`,
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right",
      });
    },
    notifyError() {
      const text = this.error;
      this.$vs.notify({
        title: "Erro",
        text: text,
        color: "danger",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right",
      });
    },
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
  },
  created() {
    this.fetchTypes();
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 500px;
    max-width: 90vw;
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
